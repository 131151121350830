<template>
  <v-card>
    <v-toolbar dark color="primary">
      <v-btn icon dark @click="$emit('dialog-toggle')">
        <v-icon>mdi-close</v-icon>
      </v-btn>

      <v-toolbar-title>{{
        dialogTrigger.tag.name + " - " + dialogTrigger.key
      }}</v-toolbar-title>

      <v-spacer></v-spacer>
    </v-toolbar>

    <v-container>
      <v-data-table
        :items="items"
        :loading="loading"
        :headers="headers"
        :items-per-page="25"
        sort-by="createdAt"
        :sort-desc="true"
        dense
      >
        <template v-slot:[`item.createdAt`]="{ item }">
          {{ humanDate(item.createdAt, "yyyy-MM-dd HH:mm:ss") }}
        </template>
      </v-data-table>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "TagHistoryDialog",
  props: ["dialogTrigger", "dialog"],

  data() {
    return {
      loading: true,
      items: [],
      headers: [
        { text: this.$t("tagdata.fields.key"), value: "key" },
        { text: this.$t("tagdata.fields.value"), value: "value" },
        { text: this.$t("tagdata.fields.createdAt"), value: "createdAt" },
      ],
    };
  },

  methods: {
    ...mapActions("tagData", {
      getTagData: "getTagDataWithMetrics",
    }),
  },

  async created() {
    this.items = await this.getTagData({
      metrics: {
        tag: this.dialogTrigger.tagDeveui,
        key: this.dialogTrigger.key,
        limit: 500,
      },
    });

    this.loading = false;
  },

  watch: {
    async dialog(value) {
      if (value) {
        this.items = await this.getTagData({
          metrics: {
            tag: this.dialogTrigger.tagDeveui,
            key: this.dialogTrigger.key,
            limit: 500,
          },
        });
      } else {
        this.items = [];
      }
    },
  },
};
</script>

<style>
</style>
