<template>
  <v-card
    no-gutter
    :style="
      'width: 100% !important; height: 100% !important; max-height: 100%; overflow-y: scroll; overflow: hidden;' +
      (soMetaBackgroundColorCard
        ? 'background-color: ' + soMetaBackgroundColorCard
        : '')
    "
    class="align-center justify-center text-center"
  >
    <v-toolbar dense flat style="background: transparent">
      <v-toolbar-title
        :style="soMetaTextColorTitle ? 'color: ' + soMetaTextColorTitle : ''"
        >{{
          $t("weather.title") +
          " - " +
          (module.tag === null ? "" : module.tag.name)
        }}</v-toolbar-title
      >
    </v-toolbar>

    <v-data-table
      :headers="headers"
      :items="weather"
      :loading="loading"
      hide-default-footer
      class="overflow-y-auto"
      style="max-height: 500px; background: transparent"
      dense
      disable-pagination
    >
      <template v-slot:[`item.wIcon`]="{ item }">
        <v-icon color="primary">{{ item.wIcon }}</v-icon>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
const SMHI = require("smhi").Forecasts;
import WeatherHelper from "@/_helpers/weather";
import Meta from "@/_helpers/ModuleMeta";

export default {
  name: "ModSMHI",

  mixins: [Meta],

  props: ["module", "timerTick", "fakeData"],

  data() {
    return {
      headers: [
        { text: this.$t("weather.icon"), value: "wIcon" },
        { text: this.$t("weather.temp"), value: "temp" },
        { text: this.$t("weather.precipitation"), value: "precipitation" },
        { text: this.$t("weather.windDir"), value: "windDir" },
        { text: this.$t("weather.windSpeed"), value: "windSpeed" },
        { text: this.$t("weather.humidity"), value: "humidity" },
        { text: this.$t("weather.thunder"), value: "thunder" },
        { text: this.$t("weather.date"), value: "date" },
      ],
      items: [],
      loading: true,
      fetching: false,
      weather: [],
    };
  },

  methods: {
    async loadData() {
      if (this.fetching === false) {
        this.fetching = true;
        let latitude = this.module.tag.latitude;
        let longitude = this.module.tag.longitude;

        SMHI.GetPointForecast(latitude, longitude).on("loaded", (data) => {
          data.timeSeries.forEach((e) => {
            let t = {};
            e.parameters.forEach((p) => {
              if (p.name === "t") t.temp = p.values[0] + "°C";
              if (p.name === "wd") t.windDir = p.values[0] + "°";
              if (p.name === "ws") t.windSpeed = p.values[0] + "m/s";
              if (p.name === "r") t.humidity = p.values[0] + "%";
              if (p.name === "tstm") t.thunder = p.values[0] + "%";
              if (p.name === "pmean") t.precipitation = p.values[0] + "mm/h";

              if (p.name === "Wsymb2")
                t.wIcon = WeatherHelper.getWeatherIcon(p.values[0]);
            });

            t.date = this.humanDate(e.validTime, "dd MMM - HH:mm");
            this.weather.push(t);
          });
        });

        this.fetching = false;
      }
    },
  },

  async created() {
    await this.loadData();
    this.loading = false;
  },
  watch: {
    timerTick(v) {
      if (v % 30 == 0) {
        this.loadData();
      }
    },
  },
};
</script>
<style></style>
