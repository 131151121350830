<template>
  <v-row no-gutters>
    <v-toolbar
      :loading="loading"
      dense
      flat
      :style="
        'background: transparent;' +
        (titleTextColor ? 'color: ' + titleTextColor : '')
      "
    >
      <v-toolbar-title>{{ module.title }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-dialog v-model="chartDialog" presistent max-width="570">
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" :loading="loading">
              <v-icon color="primary">mdi-calendar-clock</v-icon>
            </v-btn>
          </template>

          <v-card>
            <v-card-title>
              <span class="headline">
                {{ $t("common.settings") }}
              </span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <DatePicker
                  :module="module"
                  v-on:update-range="(val) => (ranges = val)"
                />
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                color="primary"
                text
                @click="saveMetrics()"
                :disabled="loading"
                >{{ $t("common.show") }}</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar-items>
    </v-toolbar>
  </v-row>
</template>

<script>
export default {
  name: "ChartControlAM",
  props: ["loading", "module", "titleTextColor"],

  data() {
    var startDate = new Date();

    return {
      chartDialog: false,
      loadingData: false,
      ranges: {
        start: startDate,
        end: new Date(),
      },
    };
  },

  methods: {
    saveMetrics() {
      this.chartDialog = false;

      this.$emit("update-chart", this.ranges);
      this.$emit("update-ranges", {
        from: this.ranges.start.toISOString(),
        to: this.ranges.end.toISOString(),
      });
    },

    async loadData() {
      this.loadingData = true;
      this.$emit("update-chart", this.ranges);
      this.loadingData = false;
    },
  },
};
</script>
